<template>
    <settings-wrapper>
        <template v-slot:child>
            <div class="center w-90 mt4">
                <img src="@/assets/images/paystack.svg" class="mt3" alt="" style="height:5rem" />

                <div class="flex justify-between mt4">
                    <div class="" style="font-size: 15px; font-weight: 700;">
                        Complete your integration
                    </div>
                </div>

                <div class="mt3">
                    To be able to get new events about payment, subscriptions and more from Paystack, we need you to set your
                    Paystack Live Webhook URL. You can find the live web hook URL in the settings page of your Paystack dashboard.
                </div>
                <div class="mt2">
                    Paystack only supports one webhook per account at the moment so confirm the field is empty.
                </div>
                <div class="box-border center mt4 integrations-card p4">
                    <div class="text-center f3" style="font-weight: 600">API Configuration Live Mode</div>

                    <div class="mt4">
                        <div class="flex justify-center">
                            <div class="formfield">
                                <label for="price">Live Secret Key</label>
                                <div class="input-wrapper">
                                    <input id="price" class="table-form" />
                                </div>
                                <font-awesome-icon
                                    v-if="!hidePassword"
                                    style="cursor: pointer"
                                    @click="togglePassword"
                                    class="field-icon eyeIcon"
                                    icon="eye"
                                />
                                <font-awesome-icon
                                    @click="togglePassword"
                                    v-if="hidePassword"
                                    style="cursor: pointer"
                                    class="field-icon eyeIcon"
                                    icon="eye-slash"
                                />
                            </div>
                        </div>

                        <div class="flex justify-center mt3">
                            <div class="formfield">
                                <label for="price">Live Public Key</label>
                                <div class="input-wrapper">
                                    <input id="price" class="table-form" />
                                </div>
                                <font-awesome-icon icon="fa-copy" class="eyeIcon" style="cursor: pointer" />
                            </div>
                        </div>

                        <div class="flex justify-center mt3">
                            <div class="formfield">
                                <label for="price">Live Callback URL</label>
                                <div class="input-wrapper">
                                    <input id="price" class="table-form" />
                                </div>
                            </div>
                        </div>

                        <div class="flex justify-center mt3">
                            <div class="formfield">
                                <label for="price">Live Webhook URL</label>
                                <div class="input-wrapper">
                                    <input id="price" class="table-form" />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="flex justify-center">
                            <label for="fs3" class="labelInputStyle">Live Secret Key</label>
                            <input class="mr3 inputBox" type="text" />
                            <font-awesome-icon
                                v-if="!hidePassword"
                                style="cursor: pointer"
                                @click="togglePassword"
                                class="field-icon eyeIcon"
                                icon="eye"
                            />
                            <font-awesome-icon
                                @click="togglePassword"
                                v-if="hidePassword"
                                style="cursor: pointer"
                                class="field-icon eyeIcon"
                                icon="eye-slash"
                            />
                        </div>
                        <div class="flex justify-center mt4">
                            <label for="fs3" class="labelInputStyle">Live Public Key</label>
                            <input class=" mr3 inputBox" type="text" />
                            <font-awesome-icon icon="fa-copy" class="eyeIcon" style="cursor: pointer" />
                        </div>
                        <div class="flex justify-center mt4">
                            <label for="fs3" class="labelInputStyle">Live Callback URL</label>
                            <input class="ml3 mr3 inputBox" type="text" />
                        </div>
                        <div class="flex justify-center mt4">
                            <label for="fs3" class="labelInputStyle">Live Webhook URL</label>
                            <input class="ml3 mr3 inputBox" type="text" />
                        </div> -->
                        <button class="mt3">Save Changes</button>
                    </div>
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import { ref } from '@vue/reactivity'
export default {
    components: { SettingsWrapper },
    setup() {
        const hidePassword = ref(true)

        const togglePassword = () => {
            hidePassword.value = !hidePassword.value
        }

        return { hidePassword, togglePassword }
    }
}
</script>

<style scoped>
.integrations-card {
    /* width: 20rem; */
    /* height: 14rem; */
    border: 1px solid #132c8c;
    border-radius: 10px;
    text-align: center;
    padding: 10px 10px 10px 10px;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.labelInputStyle {
    font-weight: 600;
    margin-top: 6px;
    font-size: 15px;
}

.inputBox {
    width: 28rem;
    height: 2.4rem;
}

.eyeIcon {
    margin-top: 6px;
    /* margin-left: 6px; */
}

.inputCont {
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.formfield {
    display: table;
    width: 40rem;
    /* margin-top: 5px; */
    /* padding-bottom: 25px; */
}

.table-form {
    height: 2.4rem;
}

.formfield label {
    font-weight: 600;
    margin-top: 6px;
    font-size: 13px;
    /* vertical-align: inherit; */
    display: table-cell;
    /* vertical-align: none; */
}

.formfield .input-wrapper {
    display: table-cell;
    /* vertical-align: top; */
}
.formfield label {
    width: 125px;
}
.formfield .input-wrapper .table-form {
    width: 100%;
}
</style>
